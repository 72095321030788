import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class Group {
  id: number;
  id_subject?: number;
  icon?: string;
  img?: string;
  level: string;
  subject: string;
  grade: string;
  group: string;
}

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  groups = new BehaviorSubject(null);
  _groups: Array<Group> = []
  _groups_mixed: Array<Group> = []

  constructor() { }

  setGroups(groups: Array<Group>, mixed?: boolean): void {
    if (mixed) {
      this._groups_mixed = groups;
    } else {
      this._groups = groups;
    }
    this.groups.next(true);
  }

  getGroups(mixed?: boolean): Array<Group> {
    return mixed ? this._groups_mixed : this._groups;
  }

  deleteGroups(): void {
    this._groups = [];
    this._groups_mixed = [];
    this.groups.next(true);
  }
}
