import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { UserService, School } from '@ser/user.service';
import { ApiService } from '@ser/api.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  images: School = {} as School;
  key: string;

  // https://i0.wp.com/britishislamicacademy.com/wp-content/uploads/2016/09/Admin.png?w=450
  constructor(
    private RT: Router,
    private US: UserService,
    private API: ApiService
  ) {
    this.RT.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd && e.url.split('/')[2] != undefined) {
        this.key = e.url.split('/')[2];
      }
    });
  }

  ngOnInit(): void {
    this.US.setLogin(this.key);
    if (this.key != undefined && this.key != 'admin') {
      this.API.get(`login/school/${this.key}`).subscribe((data: School) => { this.images = data })
    } else {
      this.images = { id: 0, name: 'Admin', logo: 'assets/logo.png', banner: 'assets/banner.png', background: 'assets/banner.png' }
    }
  }
}
