import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { notificationInterface } from '../notification.interface';
import { ApiService } from '@ser/api.service';

@Component({
  selector: 'app-opem-notification',
  templateUrl: './opem-notification.component.html',
  styleUrls: ['./opem-notification.component.css']
})
export class OpemNotificationComponent implements OnChanges {

  constructor(
    public apiService: ApiService
  ) { }

  @Input() data: notificationInterface = {} as notificationInterface;
  @Output() view = new EventEmitter<notificationInterface>();

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.viewNotifications(simpleChanges.data.currentValue.id);
  }

  viewNotifications(id: number): void {
    this.apiService.patch('notifications/' + id + '/', { visible: false }).subscribe((data: notificationInterface) => {
      this.view.emit(data);
    }, error => {
      console.log(error);
    });
  }
}
