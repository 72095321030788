<p class="text-dark"><strong>Recupera tu cuenta</strong><br>Ingresa tu nombre de usuario o correo electrónico para restablecer tu contraseña</p>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group form-animate-text" [ngClass]="{ 'form-animate-error': submitted && f.email.errors }">
    <input type="email" class="form-control" formControlName="email" name="email" autocomplete="off" placeholder="Correo electrónico" required>
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback d-block">
        <div *ngIf="f.email.errors.required">El correo electrónico es requerido</div>
        <div *ngIf="f.email.errors.email">El correo electrónico debe ser una dirección de correo electrónico válida</div>
    </div>
  </div>

  <div class="row btns">
      <div class="col text-center pb-2 d-flex">
        <button class="btn btn-primary btn-lg mx-2" type="submit" [disabled]="btnloader" [ngClass]="{ 'no-drop': btnloader }">
          <i class="fas fa-spinner fa-spin mr-3" *ngIf="btnloader"></i>BUSCAR
        </button>
        <button class="btn btn-outline-secondary btn-lg mx-2" type="button" data-dismiss="modal" aria-label="Close">cancelar</button>
      </div>
  </div>
</form>
