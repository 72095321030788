import { Params } from "@angular/router";

export enum ERole {
  Admin = 1,
  Direction = 2,
  Moderator = 3,
  Teacher = 4,
  Student = 5,
  Parent = 6,
  Editor = 7,
}

export interface MenuItems {
  name?: string;
  img?: string;
  url?: string;
  target?: string;
  prms?: Params;
  role?: ERole[];
  icon?: string;
  children?: MenuItems[];
  header?: boolean;
}
export interface Dropdowns {
  title?: string;
  icon?: string;
  badge?: string;
  body: {
    iClass?: string;
    array?: string;
    icon?: boolean;
    detail?: string;
    time?: string;
    img?: boolean;
    title?: string;
    profile?: boolean;
    logout?: boolean;
    school?: boolean;
  };
  footer?: {
    text: string;
    href: string;
  };
}

export const SUBJECT_ITEMS: MenuItems[] = [
  {
    name: "Presentación",
    url: "Presentación",
    icon: "fas fa-list-alt",
    role: [
      ERole.Direction,
      ERole.Moderator,
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
    ],
  },
  {
    name: "Contenido",
    url: "Contenido",
    icon: "fas fa-pencil-alt",
    role: [
      ERole.Direction,
      ERole.Moderator,
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
    ],
  },
  {
    name: "Centro de actividades",
    url: "Centro de actividades",
    icon: "fas fa-inbox",
    role: [
      ERole.Direction,
      ERole.Moderator,
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
    ],
  },
  {
    name: "Foro de debate",
    url: "Foro de debate",
    icon: "fas fa-users",
    role: [
      ERole.Direction,
      ERole.Moderator,
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
    ],
  },
  {
    name: "Docente",
    url: "Docente",
    icon: "fas fa-chalkboard-teacher",
    role: [
      ERole.Direction,
      ERole.Moderator,
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
    ],
  },
  {
    name: "Administración de clases",
    url: "Administración de clases",
    icon: "far fa-calendar-check",
    role: [ERole.Direction, ERole.Moderator, ERole.Teacher],
  },
  {
    name: "Aula en vivo",
    url: "Aula en vivo",
    icon: "fas fa-video",
    role: [
      ERole.Direction,
      ERole.Moderator,
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
    ],
  },
];

export const LINKS = [
  {
    img: "assets/svg/escoolar-blanco.svg",
    alt: "Escoolar",
    url: "https://escoolar.com/Principal",
    width: "100%",
  },
  {
    img: "assets/icons/microelarnings.svg",
    alt: "Britannica Escolar",
    url: "https://escoolar.com/Micrositios/37",
    width: "90%",
  },
  {
    img: "assets/icons/learn360.png",
    alt: "Britannica ImageQuest",
    url: "https://escoolar.com/Micrositios/38",
    width: "75%",
  },
];

export const LINKS_CLUB = [
  {
    img: "assets/svg/escoolar-blanco.svg",
    alt: "Escoolar",
    url: "https://escoolar.com/Principal",
    width: "100%",
  },
];

export const MANAGERSUM = [
  {
    name: "Gestor de eventos",
    url: "eventssum",
    icon: "fas fa-video",
    role: [ERole.Direction, ERole.Moderator],
  },
  {
    name: "Gesto de comunicados",
    url: "makesum",
    icon: "fas fa-video",
    role: [ERole.Direction, ERole.Moderator],
  },
];
export const MANAGERREPO = [
  /*{
    name: "Creador de comunicado",
    url: "repomakecomu",
    icon: "fas fa-video",
    role: [ERole.Admin, ERole.Editor],
  },*/
  {
    name: "Creador de contenido",
    url: "repomakecontent",
    icon: "fas fa-video",
    role: [ERole.Admin, ERole.Editor],
  },
  {
    name: "Creador de actividad",
    url: "repomakeactivity",
    icon: "fas fa-video",
    role: [ERole.Admin, ERole.Editor],
  },
];
export const CONFERENCE_REPRESENT = [
  /*{
    name: "Creador de comunicado",
    url: "repomakecomu",
    icon: "fas fa-video",
    role: [ERole.Admin, ERole.Editor],
  },*/
  {
    name: "Reunión con Dirección",
    url: "sala-reunion-director",
    icon: "fas fa-podcast rounded",
    role: [ERole.Parent],
  },
  {
    name: "Reunión con Docentes",
    url: "sala-reunion-docente",
    icon: "fas fa-podcast rounded",
    role: [ERole.Parent],
  },
];

export const _MENUITEMS: MenuItems[] = [
  // Admin
  {
    name: "Licencias",
    children: null,
    url: "/admin/licencias",
    icon: "fas fa-id-badge",
    role: [ERole.Admin],
  },
  {
    name: "Escuelas",
    children: null,
    url: "/admin/escuelas",
    icon: "fas fa-school",
    role: [ERole.Admin],
  },
  // Direction

  {
    name: "S.U.M",
    children: null,
    url: "/SUM",
    icon: "far fa-dot-circle",
    role: [
      ERole.Direction,
      ERole.Moderator,
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
    ],
  },
  /*
  {subl
    name: "Sala de reuniones",
    children: CONFERENCE_REPRESENT,
    url: "/sala-reuniones",
    icon: "fas fa-podcast rounded",
    role: [ERole.Parent, ],
  },
*/

  {
    name: "Administración",
    children: null,
    url: "/administracion",
    icon: "fas fa-layer-group",
    role: [ERole.Direction, ERole.Moderator],
  },
  {
    name: "Escuela",
    children: null,
    url: "/Escuela",
    icon: "fas fa-layer-group",
    role: [ERole.Direction, ERole.Moderator],
  },
  {
    name: "Foro docentes",
    children: null,
    url: "/maestro/foro",
    icon: "far fa-comment-alt",
    role: [ERole.Teacher, ERole.Direction, ERole.Moderator],
  },
  {
    name: "Grupos Mixtos",
    children: null,
    url: "/Grupos Mixtos",
    icon: "fas fa-users",
    role: [ERole.Direction, ERole.Moderator],
  },
  // {
  //   name: "S.U.M",
  //   children: null,
  //   url: "/users",
  //   icon: "fas fa-record-vinyl",
  //   role: [ERole.Student]
  // },
  {
    name: "Calendario",
    children: null,
    url: "/calendario",
    icon: "far fa-calendar-alt",
    role: [ERole.Student, ERole.Teacher, ERole.Direction, ERole.Moderator],
  },
  {
    name: "Mis comunicados",
    children: null,
    url: "/myrealeases",
    icon: "fas fa-bullhorn",
    role: [
      ERole.Student,
      ERole.Teacher,
      ERole.Direction,
      ERole.Moderator,
      ERole.Parent,
    ],
  },

  {
    name: "Gestor Bibliteca",
    children: MANAGERREPO,
    url: "/repo",
    icon: "fas fa-plus-square",
    role: [ERole.Admin, ERole.Editor],
  },

  {
    name: "Biblioteca",
    children: null,
    url: "/library",
    icon: "fas fa-search rounded",
    role: [
      ERole.Admin,
      ERole.Direction,
      ERole.Editor,
      ERole.Moderator,
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
    ],
  },

  {
    name: "Contenido",
    children: LINKS,
    url: "#content",
    target: "content",
    icon: "fas fa-plus-square",
    role: [
      ERole.Admin,
      ERole.Direction,
      ERole.Moderator,
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
    ],
  },

  {
    name: "Centro de calificaciones",
    children: null,
    url: "/Centro-de-calificaciones",
    icon: "fas fa-info-circle rounded",
    role: [
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
      ERole.Direction,
      ERole.Moderator,
    ],
  },

  // Herramientas
  {
    name: "Herramientas",
    header: true,
    role: [ERole.Direction, ERole.Moderator, ERole.Teacher, ERole.Student],
  },

  {
    name: "Crear un contenido",
    children: null,
    url: "/crear-contenido-alumno",
    icon: "fas fa-book-reader",
    role: [ERole.Student],
  },
  {
    name: "Mis contenidos",
    children: null,
    url: "/contenidos-alumno",
    icon: "fas fa-search rounded",
    role: [ERole.Student],
  },

  {
    name: "Crear un contenido",
    children: null,
    url: "/maestro/contenido",
    icon: "fas fa-book-reader",
    role: [ERole.Teacher],
  },

  {
    name: "Crear una actividad",
    children: null,
    url: "/maestro/actividades",
    icon: "fab fa-wpforms",
    role: [ERole.Teacher],
  },
  {
    name: "Crear una clase",
    children: null,
    url: "/maestro/clase",
    icon: "far fa-calendar-check",
    role: [ERole.Direction, ERole.Moderator],
  },

  {
    name: "Comunicado",
    children: null,
    url: "/Comunicados",
    icon: "fas fa-bullhorn",
    role: [ERole.Direction, ERole.Moderator],
  },

  {
    name: "Gestor SUM",
    children: MANAGERSUM,
    url: "/gestorsum",
    icon: "fas fa-plus-square",
    role: [ERole.Direction, ERole.Moderator],
  },

  /*
  {
    name: "Crear un S.U.M",
    children: null,
    url: "/makesum",
    icon: "fas fa-bullhorn",
    role: [ERole.Direction, ERole.Moderator]
  },
  */
  {
    name: "Usuarios",
    children: null,
    url: "/users",
    icon: "fas fa-user-tag",
    role: [ERole.Admin, ERole.Direction, ERole.Moderator],
  },
  {
    name: "Gestion de tutores",
    children: null,
    url: "/fathers",
    icon: "fas fa-bullhorn",
    role: [ERole.Direction, ERole.Moderator],
  },
];

export const _MENUITEMS_CLUB: MenuItems[] = [
  {
    name: "Home",
    children: null,
    url: "/#",
    icon: "fas fa-home",
    role: [ERole.Student, ERole.Teacher, ERole.Direction, ERole.Moderator, ERole.Parent,],
  },
  // Admin
  {
    name: "Licencias",
    children: null,
    url: "/admin/licencias",
    icon: "fas fa-id-badge",
    role: [ERole.Admin],
  },
  {
    name: "Escuelas",
    children: null,
    url: "/admin/escuelas",
    icon: "fas fa-school",
    role: [ERole.Admin],
  },
  // Direction

  {
    name: "S.U.M",
    children: null,
    url: "/SUM",
    icon: "far fa-dot-circle",
    role: [
      ERole.Direction,
      ERole.Moderator,
      ERole.Teacher,
      //ERole.Student,
      ERole.Parent,
    ],
  },
  {
    name: "Administración",
    children: null,
    url: "/administracion",
    icon: "fas fa-layer-group",
    role: [ERole.Direction, ERole.Moderator],
  },
  {
    name: "Escuela",
    children: null,
    url: "/Escuela",
    icon: "fas fa-layer-group",
    role: [ERole.Direction, ERole.Moderator],
  },
  {
    name: "Foro docentes",
    children: null,
    url: "/maestro/foro",
    icon: "far fa-comment-alt",
    role: [ERole.Teacher, ERole.Direction, ERole.Moderator],
  },
  {
    name: "Grupos Mixtos",
    children: null,
    url: "/Grupos Mixtos",
    icon: "fas fa-users",
    role: [ERole.Direction, ERole.Moderator],
  },
  {
    name: "Calendario",
    children: null,
    url: "/calendario",
    icon: "far fa-calendar-alt",
    role: [ERole.Student, ERole.Teacher, ERole.Direction, ERole.Moderator],
  },
  {
    name: "Mis comunicados",
    children: null,
    url: "/myrealeases",
    icon: "fas fa-bullhorn",
    role: [
      ERole.Student,
      ERole.Teacher,
      ERole.Direction,
      ERole.Moderator,
      ERole.Parent,
    ],
  },
  {
    name: "Centro de calificaciones",
    children: null,
    url: "/Centro-de-calificaciones",
    icon: "fas fa-info-circle rounded",
    role: [
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
      ERole.Direction,
      ERole.Moderator,
    ],
  },
  {
    name: "Gestor Bibliteca",
    children: MANAGERREPO,
    url: "/repo",
    icon: "fas fa-plus-square",
    role: [ERole.Admin, ERole.Editor],
  },

  {
    name: "Biblioteca",
    children: null,
    url: "/library",
    icon: "fas fa-search rounded",
    role: [
      ERole.Admin,
      ERole.Direction,
      ERole.Editor,
      ERole.Moderator,
      ERole.Teacher,
      //ERole.Student,
      ERole.Parent,
    ],
  },
  {
    name: "Contenido",
    children: null,
    url: "#",
    target: "content",
    icon: "fas fa-plus-square",
    role: [
      ERole.Admin,
      ERole.Direction,
      ERole.Moderator,
      ERole.Teacher,
      ERole.Student,
      ERole.Parent,
    ],
  },
  // Herramientas
  {
    name: "Herramientas",
    header: true,
    role: [ERole.Direction, ERole.Moderator, ERole.Teacher, ERole.Student],
  },
  {
    name: "Crear un contenido",
    children: null,
    url: "/crear-contenido-alumno",
    icon: "fas fa-book-reader",
    role: [ERole.Student],
  },
  {
    name: "Mis contenidos",
    children: null,
    url: "/contenidos-alumno",
    icon: "fas fa-search rounded",
    role: [ERole.Student],
  },
  {
    name: "Crear un contenido",
    children: null,
    url: "/maestro/contenido",
    icon: "fas fa-book-reader",
    role: [ERole.Teacher],
  },
  {
    name: "Crear una actividad",
    children: null,
    url: "/maestro/actividades",
    icon: "fab fa-wpforms",
    role: [ERole.Teacher],
  },
  {
    name: "Crear una clase",
    children: null,
    url: "/maestro/clase",
    icon: "far fa-calendar-check",
    role: [ERole.Direction, ERole.Moderator],
  },
  {
    name: "Comunicado",
    children: null,
    url: "/Comunicados",
    icon: "fas fa-bullhorn",
    role: [ERole.Direction, ERole.Moderator],
  },

  {
    name: "Gestor SUM",
    children: MANAGERSUM,
    url: "/gestorsum",
    icon: "fas fa-plus-square",
    role: [ERole.Direction, ERole.Moderator],
  },
  {
    name: "Usuarios",
    children: null,
    url: "/users",
    icon: "fas fa-user-tag",
    role: [ERole.Admin, ERole.Direction, ERole.Moderator],
  },
  {
    name: "Gestion de tutores",
    children: null,
    url: "/fathers",
    icon: "fas fa-bullhorn",
    role: [ERole.Direction, ERole.Moderator],
  },
];

export const DROPDOWNS: Dropdowns[] = [
  {
    title: "Notificaciones",
    icon: "fa fa-bell",
    badge: "badge-warning",
    body: {
      iClass: "text-warning",
      array: "notifications",
      icon: true,
      detail: "text",
      time: "date",
    },
    footer: { text: "Ver todas las notificaciones", href: "/notifications" },
  },
  {
    title: "Mensajes",
    icon: "fa fa-envelope",
    badge: "badge-success",
    body: {
      iClass: "text-success",
      array: "messages",
      img: true,
      title: "name",
      detail: "text",
    },
    /*
    footer: { text: "Ver todos los mensajes", href: "#" },*/
  },
  {
    icon: "fa fa-cog",
    body: {
      profile: true,
      logout: true,
      school: false,
    },
  },
];
