import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {

  @Input() img: string;
  @Input() rol: number;
  @Input() circle: boolean;
  
}
