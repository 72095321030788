import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthComponent } from '@auth/auth.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RequestAccessComponent } from './request-access/request-access.component';
import { SvgModule } from '@sha/svg/svg.module';
import { ModalAuthModule } from '@sha/modals/modal-auth/modal-auth.module';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    ResetPasswordComponent,
    RequestAccessComponent
  ],
  imports: [
    RouterModule.forChild([
      {
        path: '', component: AuthComponent,
        children: [
          { path: '', pathMatch: 'full', redirectTo: `login/${localStorage.getItem('campuus.urlLogin') ? localStorage.getItem('campuus.urlLogin') : 'admin'}` },
          { path: 'login/:school', component: LoginComponent },
          { path: 'reset-password', component: ResetPasswordComponent },
          { path: 'request-access', component: RequestAccessComponent }
        ]
      }
    ]),
    CommonModule,
    ReactiveFormsModule,
    ModalAuthModule,
    SvgModule
  ]
})
export class AuthModule { }
