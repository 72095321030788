import { Component, OnInit, Input } from '@angular/core';
import { notificationInterface } from './notification.interface';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  constructor() { }

  @Input() data:notificationInterface = {} as notificationInterface;

  ngOnInit(): void {
  }

}
