<div class="loader" [class.visible]="active">
  <div class="circle-loader">
    <svg height="200" width="200">
      <circle class="path" cx="100" cy="100" r="95" fill-opacity="0" />
      <circle class="filler" cx="100" cy="100" r="95" fill-opacity="0" />
    </svg>

    <img [src]="logo" [width]="100" [height]="100" alt="">

  </div>
</div>
