import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';
import { RouterModule } from '@angular/router';
import { TaskStatusModule } from '../task-status/task-status.module';
import { SvgModule } from '../svg/svg.module';

@NgModule({
  declarations: [NotificationComponent],
  imports: [
    CommonModule,
    RouterModule,
    TaskStatusModule,
    SvgModule
  ],
  exports:[NotificationComponent]
})
export class NotificationItemModule { }
