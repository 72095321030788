import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '@ser/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private r: Router, private u: UserService) { }
  canActivate() {
    if (!this.u.get()) {
      return true;
    } else {
      this.r.navigate(['home']);
      return false;
    }
  }
}
