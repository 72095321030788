<div class="page-wrapper default-theme sidebar-bg bg1" [class.toggled]="toggled" [class.pinned]="pinned" [class.hovered]="hovered">
    <!-- sidebar  -->
    <div id="sidebar" class="sidebar-wrapper nav toggled" (mouseover)="hovered=true" (mouseout)="hovered=false">
        <!-- sidebar-content -->
        <div class="sidebar-content">
            <div class="px-3 pt-3"><a class="btn btn-outline-link btn-block btn-lg" role="button" id="pin-sidebar" aria-label="pin-sidebar" (click)="pinned = !pinned"><i class="fa fa-align-justify"></i></a></div>
            <div *ngIf="school" class="sidebar-item sidebar-brand pt-5">
                <div class="logo" style="margin: -7px;"><a  [routerLink]="['/']"><img [src]="school.logo" [alt]="school.name" width="60"></a></div>
                <a class="ml-3" [routerLink]="['/']">
                    <h4><strong>{{school.name}}</strong></h4>
                </a>
            </div>
            <div class="sidebar-item sidebar-header d-flex flex-nowrap py-5">
                <div class="user-pic">
                  <avatar [rol]="user?.role" [img]="user?.img"></avatar>
                </div>
                <div class="user-info">
                  <badge-rol [rol]="user?.role"></badge-rol>
                  <span class="user-name" style="color: #ffffff;">{{user?.name}}</span>
                  <span class="user-status"><i class="fas fa-dot-circle"></i><span>Online</span></span>
                </div>
            </div>
            <div class="sidebar-item sidebar-menu">
                <ul *ngIf="user.role!= 1 && user.role!=7">
                    <li [@listStagger]="menuItems" *ngFor="let item of menuItems" routerLinkActive="active" [class.sidebar-dropdown]="item.children !== null" [class.header-menu]="item.header">
                      <!-- item -->
                      <a *ngIf="!item.header && (schoolIDFlag != school.id)"  href="#" [routerLink]="[item.url]" [queryParams]="item.prms"
                        [attr.data-toggle]="item.children !== null ? 'collapse': ''"
                        [attr.data-target]="item.children !== null ? '#'+item.target : ''"
                      >
                        <i [class]="item.icon"></i>
                        <span class="menu-text"><strong>{{item.name}}</strong></span>
                      </a>
                      <a *ngIf="!item.header && (schoolIDFlag == school.id) && item.name !== 'Contenido'"  href="#" [routerLink]="[item.url]" [queryParams]="item.prms"
                        [attr.data-toggle]="item.children !== null ? 'collapse': ''"
                        [attr.data-target]="item.children !== null ? '#'+item.target : ''"
                      >
                        <i [class]="item.icon"></i>
                        <span class="menu-text"><strong>{{item.name}}</strong></span>
                      </a>
                      <!-- submenu  -->
                      <div *ngIf="!item.header && item.children !== null" class="sidebar-submenu pl-4 collapse" [id]="item.target ? item.target : ''" aria-expanded="true">
                          <ul>
                              <li *ngFor="let subitem of item.children" routerLinkActive="active">
                                <a *ngIf="subitem.name" class="content" [@listStagger]="item.children" [routerLink]="[item.url, subitem.url]" [queryParams]="item.prms" style="font-size: small;"><strong>{{subitem.name}}</strong></a>

                                <!--<div style="margin-top:10px"*ngIf="subitem.img"  id="codigoIncrustacion"></div>-->
                                <a *ngIf="subitem.img === 'assets/svg/escoolar-blanco.svg'" onclick="ssoToken()" style="cursor: pointer;" target="_blank"><img class="py-2" [src]="subitem.img" [alt]="subitem.alt" width="80%" style="max-width:'180px'"></a>
                                <a *ngIf="(subitem.img && subitem.img !== 'assets/svg/escoolar-blanco.svg')" [href]="subitem.url" target="_blank"><img class="py-2" [src]="subitem.img" [alt]="subitem.alt" [attr.width]="subitem.width" style="max-width:'180px'"></a>
                              </li>
                          </ul>
                      </div>
                      <!-- title -->
                      <span *ngIf="item.header">{{item.name}}</span>
                    </li>
                    <li *ngIf="(schoolIDFlag == school.id)">
                      <a id="escoolarAcces" style="cursor: pointer;" target="_blank" href="https://escoolar.com/Principal">
                        <img class="py-2" src="assets/svg/escoolar-blanco.svg" width="80%" style="max-width:'125px'">
                      </a>
                    </li>
                </ul>
                <ul *ngIf="user.role== 1 || user.role==7">
                    <li [@listStagger]="menuItems" *ngFor="let item of menuItems" routerLinkActive="active" [class.sidebar-dropdown]="item.children !== null" [class.header-menu]="item.header">
                      <!-- item -->
                      <a *ngIf="!item.header"  href="#" [routerLink]="[item.url]" [queryParams]="item.prms"
                        [attr.data-toggle]="item.children !== null ? 'collapse': ''"
                        [attr.data-target]="item.children !== null ? '#'+item.target : ''"
                      >
                        <i [class]="item.icon"></i>
                        <span class="menu-text"><strong>{{item.name}}</strong></span>
                      </a>
                      <!-- submenu  -->
                      <div *ngIf="!item.header && item.children !== null" class="sidebar-submenu pl-4 collapse" [id]="item.target ? item.target : ''" aria-expanded="true">
                          <ul>
                              <li *ngFor="let subitem of item.children" routerLinkActive="active">
                                <a *ngIf="subitem.name" class="content" [@listStagger]="item.children" [routerLink]="[item.url, subitem.url]" [queryParams]="item.prms" style="font-size: small;"><strong>{{subitem.name}}</strong></a>

                                <!--<div style="margin-top:10px"*ngIf="subitem.img"  id="codigoIncrustacion"></div>-->
                                <a *ngIf="subitem.img === 'assets/svg/escoolar-blanco.svg'" onclick="ssoToken()" style="cursor: pointer;" target="_blank"><img class="py-2" [src]="subitem.img" [alt]="subitem.alt" width="80%" style="max-width:'180px'"></a>
                                <a *ngIf="(subitem.img && subitem.img !== 'assets/svg/escoolar-blanco.svg')" [href]="subitem.url" target="_blank"><img class="py-2" [src]="subitem.img" [alt]="subitem.alt" [attr.width]="subitem.width" style="max-width:'180px'"></a>
                              </li>
                          </ul>
                      </div>
                      <!-- title -->
                      <span *ngIf="item.header">{{item.name}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- sidebar-footer -->
        <div class="sidebar-footer">
            <!-- dropdowns -->
            <div class="dropdown" *ngFor="let item of dropdowns">
                <a  href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i [ngClass]="item.icon"></i><span   *ngIf="item.title === 'Mensajes' && messages.length > 0 "  class="badge badge-pill badge-success notification">{{numeroDeNotificaciones}}</span>
                    <!-- ç
<span class="badge badge-pill badge-success notification">7</span>

                      <span *ngIf="item.badge" [ngClass]="item.badge" class="badge badge-pill notification">{{notifications.length}}</span> -->
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuMessage">
                    <div *ngIf="item.title" class="dropdown-header text-dark">
                        <i [ngClass]="[item.icon, item.body.iClass]"></i>
                        <strong> {{item.title}}</strong>
                        <div class="dropdown-divider"></div>
                    </div>
                    <!--
                    <a class="dropdown-item" *ngFor="let i of this[item.body.array]" [routerLink]="'/chats/'+i.user_id">-->

                    <a class="dropdown-item" *ngFor="let i of this[item.body.array]" (click)="modalChat(i.user_id)">
                        <div class="dropdown-content">
                            <div class="pic">
                                <i *ngIf="item.body.icon" class="fas border rounded" [ngClass]="{ 'fa-check text-success border-success' : i.type == 1, 'fa-exclamation text-info border-info': i.type == 2, 'fa-exclamation-triangle text-warning border-warning': i.type == 3 }"></i>
                                <img *ngIf="item.body.img" class="rounded-circle" [src]="i.img" [alt]="i.name" loading="lazy">
                            </div>
                            <div class="content">
                                <div *ngIf="item.body.title" class="title text-dark"><strong> {{i[item.body.title]}}</strong></div>
                                <div *ngIf="item.body.detail" class="detail text-dark">{{i[item.body.detail]}}</div>
                                <div *ngIf="item.body.time" class="time text-dark">{{i[item.body.time]}}</div>
                            </div>
                        </div>
                    </a>
                    <a *ngIf="item.body.profile" class="dropdown-item text-dark" [routerLink]="['/perfil']">Editar mi perfil</a>
                    <a *ngIf="item.body.school" class="dropdown-item text-dark" [routerLink]="['/school']">Configurar Escuela</a>
                    <a *ngIf="item.body.logout" class="dropdown-item text-dark" (click)="logout()">Cerrar Sesion</a>
                    <a *ngIf="item.footer" [routerLink]="item.footer.href" class="dropdown-item text-center"><div class="dropdown-divider"></div>{{item.footer.text}}</a>
                </div>
            </div>
            <!-- icon for pinned -->
            <div class="pinned-footer">
                <a href="#">
                    <i class="fas fa-ellipsis-h"></i>
                </a>
            </div>
        </div>
    </div>
    <!-- page -->
    <main class="page-content">
        <div id="overlay" class="overlay" (click)="collapse(false);"></div>
        <div></div>
        <section *ngIf="school" class="d-flex d-xl-flex align-items-end position-relative" [ngStyle]="{'background-image': 'linear-gradient(transparent 0%, #151825), url(' + school.banner + ')'}" style="background: linear-gradient(230deg, rgba(30,34,54,0) 0%, rgba(30,34,54,0.8) 56%), url(' + school.background + ') center center / cover no-repeat;min-height: 25vh;">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h1 class="display-4 mb-3 text-white"><strong>{{school.name}}</strong></h1>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="row">
                <div class="col">
                    <div class="form-group col-md-12 mt-3"><button class="btn btn-dark btn-lg" id="toggle-sidebar" type="button" (click)="toggled = !toggled">&nbsp;MENú</button></div>
                </div>
            </div>
        </section>
        <section>
          <div class="container" id="mastercontainer">
            <!-- <breadcrumb></breadcrumb> -->
            <router-outlet></router-outlet>
          </div>
          <footer></footer>
        </section>
    </main>
</div>

<loader></loader>



<section>
  <div
    class="modal fade"
    tabindex="-1"
    id="chatModal"
    aria-hidden="true"
    style="display: none"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="height:600px">
        <div class="modal-header">
          <h4 class="modal-title text-white">Mensajes</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body bg-white pt-0">
          <div class="py-2 mb-4" style="background: #eceff1">
            <div id="chat-mensajes" style="height:500px">
              <div
              *ngFor="let msg of mensajes;"
              >
              <div class="card-body" style="padding-bottom: 10px;">
                <div class="row ng-star-inserted">
                  <div class="col">
                    <h5
                      class="text-dark d-flex align-items-center"
                      style="margin-bottom: 0px"
                    >
                      <div style="width: 30px; height: 30px">
                        <avatar
                         [circle]="true"
                         [rol]="msg.from.role"
                         [img]="msg.from.img"
                          ></avatar>
                      </div>
                      &nbsp;&nbsp;{{msg.from.name}}&nbsp;&nbsp;
                      <badge-rol [rol]="msg.role"
                        ></badge-rol
                      >
                    </h5>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <small class="text-black-50 text-muted pl-4"
                      > {{tranformarFecha(msg.date) | date: 'dd MMM yyyy' : '' : 'es'}} &#64; {{tranformarFecha(msg.date) | date:'shortTime'}}</small
                    >
                    <p class="text-dark pl-4">
                      {{msg.message}} <br />
                    </p>
                  </div>
                </div>
              </div>

            </div>

            </div>
            <form (ngSubmit)="enviar()" style="margin-top: 10px" class="col-12">
              <input
              type="text"
              [(ngModel)]="texto"
              class="form-control chat-input"
              name="texto"
              placeholder="Mensaje..."
              autofocus
              autocomplete="off"
              >
              <button type="submit"  class="btn btn-outline-primary m-10" style="margin-top: 15px;">Enviar mensaje</button>
            </form>
          </div>



        </div>

      </div>
    </div>
  </div>
</section>






