import { Component } from '@angular/core';
import { LoaderService } from '@ser/loader.service';
import { UserService } from '@ser/user.service';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {

  active: boolean;
  logo: string;

  constructor(private LS: LoaderService, private USER: UserService) {
    this.LS.status.subscribe((status: boolean) => { this.active = status; })
    this.USER.update.subscribe(() => { this.logo = this.USER.getLogo(); });
  }

}
