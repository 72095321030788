import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '@ser/api.service';
import { UserService } from '@ser/user.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth.component.css']
})
export class LoginComponent implements OnInit {

  sub: Subscription;
  form: FormGroup;
  submitted: boolean = false;
  btnloader: boolean = false;

  constructor(
    private FB: FormBuilder,
    private API: ApiService,
    private US: UserService
  ) { }

  ngOnInit(): void {
    this.form = this.FB.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  get f() { return this.form.controls; }
  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) { return; }
    this.btnloader = true;


    let bodynew = this.form.value;


    var today = new Date();
  	var jan = new Date(today.getFullYear(), 0, 1);
  	var jul = new Date(today.getFullYear(), 6, 1);
  	var dst = today.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

    bodynew.offset = -today.getTimezoneOffset() / 60;
    bodynew.dst = +dst;


    this.sub = this.API.post('login/', bodynew).subscribe((data: { token: string }) => {
      localStorage.setItem('timer', Date.now().toString());
      this.US.set(data)
    }, (err:any) => {
      Swal.fire({ type: 'error', title: err.error.mensaje, showConfirmButton: true, timer: 2500, customClass: { confirmButton: 'btn btn-sm btn-primary', cancelButton: 'btn btn-sm btn-secondary' } })
    });


    this.sub.add(() => this.btnloader = false);
  }

  ngOnDestroy(): void {
    // this.sub.unsubscribe();
  }
}
