import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';

export class User {
  id: number;
  name: string;
  email: string;
  role: number;
  school?: School;
  img?: string;
  time_zone?: any;

  constructor(data: object) {


    this.id = data['id']
    this.name = data['name'];
    this.email = data['email'];
    this.role = data['role'];
    this.school = data['school'];
    this.img = data['img'];
    this.time_zone = data['time_zone'];



    if (data['school']) {
      localStorage.setItem('campuus.school', JSON.stringify(data['school']))
    }

  }
}

export class School {
  id: number;
  name: string;
  logo: string;
  banner: string;
  background: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  update = new BehaviorSubject(null);

  constructor(
    private r: Router
  ) { }

  set(data: Object): void {
    localStorage.setItem('campuus', this.utf8_to_b64(data['token']));
    this.setUser(new User(jwt_decode(data['token'])['data']));
    setTimeout(() => {
      this.r.navigate(["home"]).then(() => {
        location.reload();
      });
    }, 500);
  }

  setNewToken = (data) => {
    localStorage.setItem('campuus', this.utf8_to_b64(data['token']));
    this.setUser(new User(jwt_decode(data['token'])['data']));
  }

  get() {
    return localStorage.getItem("campuus") ? jwt_decode(this.b64_to_utf8(localStorage.getItem("campuus"))) : null;
  }

  setUser(user: User) {
    localStorage.setItem('campuus-user', this.utf8_to_b64(JSON.stringify(user)));
    this.update.next(true);
  }

  getUser(): User {
    return localStorage.getItem("campuus-user") ? JSON.parse(this.b64_to_utf8(localStorage.getItem("campuus-user"))) : null;
  }
  getLogo(): string {
    return localStorage.getItem("campuus.school") ? JSON.parse(localStorage.getItem("campuus.school")).logo : 'assets/loader.png';
  }

  // url login
  setLogin(url: string): void {
    if (url != undefined) {
      localStorage.setItem('campuus.urlLogin', url)
    }
  }
  getLogin(): string {
    return localStorage.getItem('campuus.urlLogin');
  }

  // information of school
  setSchool(data: School): void {
    localStorage.setItem('campuus.school', JSON.stringify(data))
    this.update.next(true);
  }
  getSchool(): School {
    return localStorage.getItem("campuus.school") ? JSON.parse(localStorage.getItem("campuus.school")) : null;
  }

  getToken(): string {
    return localStorage.getItem("campuus") ? this.b64_to_utf8(localStorage.getItem("campuus")) : null;
  }

  utf8_to_b64(str: string): string {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  b64_to_utf8(str: string): string {
    return decodeURIComponent(escape(window.atob(str)));
  }

  logout() {
    localStorage.removeItem('campuus');
    localStorage.removeItem('campuus-user');
    localStorage.removeItem('campuus.school');
    localStorage.removeItem('timer');
    this.update.next(true);


  }

  obtenerUrl = () =>{
    return  this.getLogin()
  }
}
