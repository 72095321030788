<div class="user-pic">
    <div class="rounded"
      [ngClass]="{
        'admin': rol == 1,
        'direction' : rol == 2 || rol == 3,
        'teacher': rol == 4,
        'student': rol == 5,
        'Parent': rol == 6
      }"
      [class.not-border]="circle && img"
    >
      <!-- <a data-toggle="tooltip" data-bs-tooltip="" href="#" title="Editar perfil"> -->
        <img class="img-fluid" [class.rounded-circle]="circle && img" [class.border]="circle && img" data-bs-hover-animate="bounce" [src]="img ? img : 'assets/svg/avatar.svg'" alt="perfil">
      <!-- </a> -->
    </div>
</div>
