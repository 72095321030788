import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '@ser/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'request-access',
  templateUrl: './request-access.component.html',
  styleUrls: ['../auth.component.css']
})
export class RequestAccessComponent implements OnInit {

  form: FormGroup;
  submitted: boolean;
  btnloader: boolean;

  constructor(
    private FB: FormBuilder,
    private API: ApiService
  ) { }

  ngOnInit(): void {
    this.form = this.FB.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get f() { return this.form.controls; }
  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) { return; }
    this.btnloader = true;

    const sub = this.API.post('login/reset/', this.form.value).subscribe((data: any) => {
      Swal.fire({
        position: 'center',
        type: 'success',
        title: 'La solicitud de acceso fue enviada correctamente verifica tu correo.',
        showConfirmButton: false,
        timer: 3500
      });
    });

    sub.add(() => this.btnloader = false);
  }
}
