import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@auth/auth.guard";
import { DashboardGuard, AdminGuard } from "@dash/dashboard.guard";
import { DashboardComponent } from "@dash/dashboard.component";

const routes: Routes = [
  {
    path: "404",
    loadChildren: () =>
      import("@sha/not-found/not-found.module").then((m) => m.NotFoundModule),
  },
  {
    path: "",
    loadChildren: () => import("@auth/auth.module").then((m) => m.AuthModule),
    canActivate: [AuthGuard],
  },
  {
    path: "",
    component: DashboardComponent,
    canActivate: [DashboardGuard],
    children: [
      { path: "", pathMatch: "full", redirectTo: "departments" },
      {
        path: "home",
        loadChildren: () =>
          import("@dash/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "admin",
        canLoad: [AdminGuard],
        loadChildren: () =>
          import("@dash/admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "Grupos Mixtos",
        loadChildren: () =>
          import("@dash/mixed-groups/mixed-groups.module").then(
            (m) => m.MixedGroupsModule
          ),
      },
      {
        path: "Escuela",
        loadChildren: () =>
          import("@dash/administration/direction/direction.module").then(
            (m) => m.DirectionModule
          ),
      },
      {
        path: "administracion",
        loadChildren: () =>
          import("@dash/administration/module").then(
            (m) => m.AdministrationModule
          ),
      },
      {
        path: "maestro",
        loadChildren: () =>
          import("@dash/teacher/teacher.module").then((m) => m.TeacherModule),
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("@dash/notification/notification.module").then(
            (m) => m.NotificationModule
          ),
      },
      {
        path: "calendario",
        loadChildren: () =>
          import("@dash/calendar/calendar.module").then(
            (m) => m.CalendarModule
          ),
      },
      {
        path: "materia",
        loadChildren: () =>
          import("@dash/subjects/subjects.module").then(
            (m) => m.SubjectsModule
          ),
      },
      {
        path: "users",
        loadChildren: () =>
          import("@dash/users/users.module").then((m) => m.UsersModule),
      },
      {
        path: "perfil",
        loadChildren: () =>
          import("@dash/users/profile/profile.module").then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: "school",
        loadChildren: () =>
          import("@dash/school/school.module").then((m) => m.SchoolModule),
      },
      {
        path: "Centro-de-calificaciones",
        loadChildren: () =>
          import("@dash/ratingscenter/ratingscenter.module").then(
            (m) => m.RatingscenterModule
          ),
      },
      {
        path: "Comunicados",
        loadChildren: () =>
          import("@dash/releases/releases.module").then(
            (m) => m.ReleasesModule
          ),
      },
      {
        path: "SUM",
        loadChildren: () =>
          import("@dash/sum/sum.module").then((m) => m.SumModule),
      },

      {
        path: "myrealeases",
        loadChildren: () =>
          import("@dash//myrealeases/myrealeases.module").then(
            (m) => m.MyrealeasesModule
          ),
      },
      {
        path: "gestorsum",
        loadChildren: () =>
          import("./components/dashboard/managersum/managersum.module").then(
            (m) => m.ManagersumModule
          ),
      },
      {
        path: "fathers",
        loadChildren: () =>
          import("./components/dashboard/fathers/fathers.module").then(
            (m) => m.FathersModule
          ),
      },
      {
        path: "library",
        loadChildren: () =>
          import("./components/dashboard/library/library.module").then(
            (m) => m.LibraryModule
          ),
      },
      {
        path: "repo",
        loadChildren: () =>
          import("./components/dashboard/repo/repo.module").then(
            (m) => m.RepoModule
          ),
      },
      {
        path: "chats",
        loadChildren: () =>
          import("./components/dashboard/chats/chats.module").then(
            (m) => m.ChatsModule
          ),
      },

      {
        path: "crear-contenido-alumno",
        loadChildren: () =>
          import(
            "./components/dashboard/makecontenidostudent/makecontenidostudent.module"
          ).then((m) => m.MakecontenidostudentModule),
      },
      {
        path: "contenidos-alumno",
        loadChildren: () =>
          import(
            "./components/dashboard/contenidostudent/contenidostudent.module"
          ).then((m) => m.ContenidostudentModule),
      },
      {
        path: "contenidos-alumno/contenido-alumno",
        loadChildren: () =>
          import(
            "./components/dashboard/detallecontenidostudent/detallecontenidostudent.module"
          ).then((m) => m.DetallecontenidostudentModule),
      },
      {
        path: "sala-reuniones",
        loadChildren: () =>
          import(
            "./components/dashboard/conference-represent-student/conference-represent-student.module"
          ).then((m) => m.ConferenceRepresentStudentModule),
      },
    ],
  },

  { path: "**", pathMatch: "full", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
