import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  items: Array<{ url: string, breadcrumb: string }> = [];

  constructor(
    private r: Router,
  ) {
    this.r.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd) {
        this.items = [];
        let accumulatedUrl = ''; // Almacena la URL acumulada

        e.url.split("/").forEach(elem => {
          if (elem != '' && !Number(elem)) {
            const breadcrumb = decodeURIComponent(elem);
            accumulatedUrl += `/${breadcrumb}`;
            this.items.push({
              url: accumulatedUrl,
              breadcrumb: breadcrumb
            });
          }
        });
      }
    })
  }

  ngOnInit(): void {
  }

}
