import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpParams, HttpErrorResponse } from '@angular/common/http'
import { environment } from '@env/environment';
import { map, tap, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { UserService } from './user.service';
import { ErrorService } from './error.service';
import { LoaderService } from './loader.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getJson() {
    return this.http.get('assets/json/data.json');
  }

  getJsonNotifications() {
    return this.http.get('assets/json/data-notification.json');
  }

  dowload(url: string, params?: HttpParams) {
    return this.http.get(url, { params: params, responseType: "blob" })
  }

  get(url: string, params?: HttpParams) {
    return this.http.get(url, { params: params })
  }

  post(url: string, data: any) {
    return this.http.post(url, data)
  }

  patch(url: string, data: any) {
    return this.http.patch(url, data)
  }

  put(url: string, data: any) {
    return this.http.put(url, data)
  }

  delete(url: string, params?: HttpParams) {
    return this.http.delete(url, { params: params })
  }

}

// intercepta las llamadas a los endpoits para setear el token
@Injectable() export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private user: UserService, private err: ErrorService, private loader: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = this.user.getToken();
    if (token) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    }
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    req = req.clone({ headers: req.headers.set('Accept-Language', 'es-mx') });

    const url = `${environment.url}${req.url}`.replace(/([^:]\/)\/+/g, '$1');
    req = req.clone({ url });

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
          this.loader.stop(1000);
        }
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        this.err.checkError(err);
        return throwError(err);
      })
    );
  }

}
