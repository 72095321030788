<section>
    <div class="row">
        <div class="col">
            <div class="modal fade" role="dialog" tabindex="-1" id="modalauth">
                <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="card bg-white">
                            <div class="card-header p-5" style="background: #eceff1;" *ngIf="img">
                              <img class="d-block d-xl-flex justify-content-xl-center mx-auto" [src]="img" style="width: 100%;">
                            </div>
                            <div class="card-body">
                                <ng-content></ng-content>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
