import {
  Component,
  OnInit,
  HostListener,
  HostBinding,
  AfterViewInit,
} from "@angular/core";
import { environment } from "@env/environment";
import { Router, Event, NavigationEnd } from "@angular/router";
import { ApiService } from "@ser/api.service";
import { LoaderService } from "@ser/loader.service";
import { UserService, User, School } from "@ser/user.service";
import {
  MenuItems,
  _MENUITEMS,
  _MENUITEMS_CLUB,
  SUBJECT_ITEMS,
  ERole,
  DROPDOWNS,
  Dropdowns,
} from "./dashboard.config";
import { PageAnimations, ListStagger } from "@ani/animations";
import { GroupsService, Group } from "@ser/groups.service";
//import { ChatService } from "@ser/chat.service";
//import { ServicesModalChatService } from "../../services/services-modal-chat.service";
import { Subscription } from "rxjs";
declare var $: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  animations: [PageAnimations, ListStagger],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @HostBinding("@pageAnimations")
  toggled: boolean;
  pinned: boolean;
  hovered: boolean;
  school: School = {} as School;
  changeNameInput: boolean;
  user: User;

  notifications: Array<any> = [
    {
      type: 1,
      date: "Hace 6 minutos",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      type: 2,
      date: "hoy",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      type: 3,
      date: "Ayer",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
  ];
  messages: Array<any> = [
    {
      img: "assets/images/profile-1.jpg",
      name: "Carlos Rufinod",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt",
    },
    {
      img: "assets/images/profile-2.jpg",
      name: "Rodrigo Bueno",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt",
    },
    {
      img: "assets/images/profile-3.jpg",
      name: "Alexis García",
      text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt",
    },
  ];

  menuItems: MenuItems[] = [];
  subjectItems: MenuItems[] = [];
  dropdowns: Dropdowns[] = [];

  texto: string = "";
  mensajesSubscription: Subscription;
  mensajes: any[] = [];
  elemento: HTMLElement;

  idPersonaEnviar;

  numeroDeNotificaciones = 0;

  schoolIDFlag = environment.schoolIDFlag;
  rol: number;

  constructor(
    private RT: Router,
    private USER: UserService,
    private API: ApiService,
    private LS: LoaderService,
    private GS: GroupsService
  ) //public ChatModal: ServicesModalChatService,
  //public chatService: ChatService
  {
    this.RT.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.LS.start();
        this.LS.stop(1000);
      }
    });
    this.USER.update.subscribe(() => {
      this.user = this.USER.getUser();
      this.school = this.USER.getSchool();
      console.log("school", this.school);
    });

    if (
      this.school != null &&
      this.user.role != 4 &&
      this.school.id == this.schoolIDFlag
    ) {
      this.menuItems = _MENUITEMS_CLUB.filter((x) => {
        return x.role.includes(this.user.role) ? x : null;
      });
    } else {
      this.menuItems = _MENUITEMS.filter((x) => {
        return x.role.includes(this.user.role) ? x : null;
      });
    }

    this.subjectItems = SUBJECT_ITEMS.filter((x) => {
      return x.role.includes(this.user.role) ? x : null;
    });
    this.dropdowns = DROPDOWNS;
    this.dropdowns[2].body.school = false;

    if (
      this.user.role != ERole.Admin &&
      this.user.role != ERole.Parent &&
      this.user.role != ERole.Editor
    ) {
      this.dropdowns[2].body.school =
        this.user.role === ERole.Direction ? true : false;

      this.getGroups();
    }
    /*
    this.ChatModal.chat.subscribe(({ user_id, open }) => {
      if (open) {
        $("#chatModal").modal("toggle");

        this.idPersonaEnviar = user_id;

        this.elemento = document.getElementById("chat-mensajes");

        this.chatService.join({ to: this.idPersonaEnviar });

        setTimeout(() => {
          this.chatService.getRoomChat().subscribe(({ code }) => {
            this.API.get("chat/message?code=" + code).subscribe((res: any) => {
              this.mensajes = [...res];
              setTimeout(() => {
                this.elemento.scrollTop = this.elemento.scrollHeight;
              }, 200);
            });
          });

          this.mensajesSubscription = this.chatService
            .getMessages()
            .subscribe((msg: any) => {
              let array = [];

              array = this.mensajes.filter((e) => e.id === msg[0].id);

              if (array.length === 0) {
                this.mensajes.push(msg[0]);
              }

              setTimeout(() => {
                this.elemento.scrollTop = this.elemento.scrollHeight;
              }, 100);
            });
        }, 200);
      } else {
        this.chatService.leave();
      }
    });
    */
  }

  tranformarFecha = (value) => {
    return new Date(value.split(" ").join("T").toString() + "Z").toString();
  };

  enviar = () => {
    if (this.texto.trim().length === 0) {
      return;
    }
    //this.chatService.sendMessage({ to: this.idPersonaEnviar, msg: this.texto });
    this.texto = "";
  };

  modalChat = (user_id) => {
    // this.ChatModal.chat.next({ user_id: user_id, open: true });
  };

  consultarNotificaciones = () => {
    this.API.get("/chat/notification/").subscribe((data: any) => {
      this.messages = [];

      this.numeroDeNotificaciones = data.count;

      data.notification.forEach(({ from, message }) => {
        let arrayimg = from.img.length > 0 ? from.img : "assets/svg/avatar.svg";

        if (this.messages.length > 0) {
          let arraytemp = this.messages.filter(
            (e) => e.user_id === from.user_id
          );

          if (arraytemp.length > 0) {
            this.messages.map((e) => {
              if (e.user_id === from.user_id) {
                e.text = message.slice(0, 49);
              }
            });
          } else {
            this.messages.push({
              img: arrayimg,
              name: from.name,
              text: message.slice(0, 49),
              user_id: from.user_id,
            });
          }
        } else {
          this.messages.push({
            img: arrayimg,
            name: from.name,
            text: message.slice(0, 49),
            user_id: from.user_id,
          });
        }
      });
    });
  };
  ngAfterViewInit(): void {
    this.consultarNotificaciones();

    $(document).ready(() => {
      $("#chatModal").on("hidden.bs.modal", () => {
        // this.ChatModal.chat.next({ user_id: 0, open: false });
        this.consultarNotificaciones();
      });
    });

    /*
 )*/
    /*
    this.chatService.getNotificaciones().subscribe(({ from, msg }) => {
      let arrayimg = from.img.length > 0 ? from.img : "assets/svg/avatar.svg";

      this.numeroDeNotificaciones++;
      setTimeout(() => {
        if (this.messages.length > 0) {
          let arraytemp = this.messages.filter(
            (e) => e.user_id === from.user_id
          );

          if (arraytemp.length > 0) {
            this.messages.map((e) => {
              if (e.user_id === from.user_id) {
                e.text = msg.slice(0, 49);
              }
            });
          } else {
            this.messages.push({
              img: arrayimg,
              name: from.name,
              text: msg.slice(0, 49),
              user_id: from.user_id,
            });
          }
        } else {
          this.messages.push({
            img: arrayimg,
            name: from.name,
            text: msg.slice(0, 49),
            user_id: from.user_id,
          });
        }
      }, 50);
    });*/
  }

  ngOnInit() {
    this.resize();
    let validadorTiempo = JSON.parse(localStorage.getItem("timer"));
    if (!validadorTiempo) {
      this.logout();
    } else {
      const tiempoTranscurrido = Date.now();
      const tiempoLimite = new Date(validadorTiempo + 1000 * 60 * 90).getTime();
      this.checkToken(tiempoLimite - tiempoTranscurrido);
    }
  }

  checkToken = (tiempo) => {
    new Promise((resolve) => {
      const intervalo = setInterval(() => {
        let validadorTiempo = JSON.parse(localStorage.getItem("timer"));
        if (Date.now() >= validadorTiempo) {
          resolve("ok");
          clearInterval(intervalo);
        }
      }, tiempo);
    }).then(() => {
      this.API.post("login/validate", {})
        .toPromise()
        .then(({ is_new, token }: any) => {
          if (is_new) {
            this.USER.setNewToken({ token });
            localStorage.setItem("timer", Date.now().toString());
            let validadorTiempo = JSON.parse(localStorage.getItem("timer"));
            const tiempoTranscurrido = Date.now();
            const tiempoLimite = new Date(
              validadorTiempo + 1000 * 60 * 90
            ).getTime();
            this.checkToken(tiempoLimite - tiempoTranscurrido);
          } else {
            if (!token) {
              this.logout();
            } else {
              let validadorTiempo = JSON.parse(localStorage.getItem("timer"));
              const tiempoTranscurrido = Date.now();
              const tiempoLimite = new Date(
                validadorTiempo + 1000 * 60 * 90
              ).getTime();
              this.checkToken(tiempoLimite - tiempoTranscurrido);
            }
          }
        });
    });
  };

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.resize();
  }

  resize(): void {
    if (window.innerWidth < 992) {
      this.toggled = false;
      if ($(".overlay").hasClass("active")) {
        $(".overlay").removeClass("active");
      }
    } else {
      this.toggled = true;
      $(".overlay").removeClass("active");
    }
  }

  collapse(boolean: boolean): void {
    if (window.innerWidth < 992) {
      if (this.toggled) {
        this.toggled = false;
        $(".overlay").removeClass("active");
      } else {
        this.toggled = true;
        $(".overlay").addClass("active");
      }
    } else if (window.innerWidth > 992 && boolean) {
      this.toggled = !this.toggled;
    }
  }

  // pide los grupos
  getGroups(): void {
    this.API.get(`manager/groups/`).subscribe((data: Array<any>) => {
      if (
        this.user.role === ERole.Teacher ||
        this.user.role === ERole.Student
      ) {
        if (
          this.school.id != this.schoolIDFlag ||
          this.user.role != ERole.Student
        ) {
          this.menuItems.push({ name: "Grupos", header: true });
          data.forEach((elem) => {
            this.menuItems.push({
              name: `${elem.subject} ${elem.grade}`,
              url: `/materia/${elem.subject} ${elem.grade} ${elem.group} ${elem.level}/${elem.id}`,
              icon: elem.icon,
              children: this.subjectItems,
            });
          });
        }
      }

      const schoolNumbers = [56, 57];
      const validateSchool = schoolNumbers.includes(this.school.id);
      if (validateSchool) {
        const indexMenu = this.menuItems.findIndex(
          (ele) => ele.name === "Contenido"
        );
        if (indexMenu > -1) {
          this.menuItems[indexMenu].children = this.menuItems[
            indexMenu
          ].children.filter((e) => e.url === "https://escoolar.com/Principal");
        }
      }

      this.getGroupsElectives();
      this.GS.setGroups(data);
    });
  }

  // pide los grupos extras
  getGroupsElectives(): void {
    this.API.get(`manager/electives/`).subscribe((data: Array<Group>) => {
      if (
        this.user.role === ERole.Teacher ||
        this.user.role === ERole.Student
      ) {
        if (
          this.school.id != this.schoolIDFlag ||
          this.user.role != ERole.Student
        ) {
          this.menuItems.push({ name: "Grupos Mixtos", header: true });
          data.forEach((elem) => {
            this.menuItems.push({
              name: `${elem.group}`,
              url: `/materia/${elem.group}/${elem.id}`,
              icon: elem.icon,
              children: this.subjectItems,
            });
          });
        }
      }
      this.GS.setGroups(data, true);
    });
  }

  // cierra la sesion
  logout(): void {
    this.API.post("login/logout", {})
      .toPromise()
      .then(() => {
        let url = this.USER.obtenerUrl();
        this.USER.logout();
        this.RT.navigateByUrl("/login" + url);
      });

    let url = this.USER.obtenerUrl();
    /*
    this.RT.navigateByUrl("/login" + url).then(() => {
      this.API.post("login/logout", {})
        .toPromise()
        .then(
          (data: { mensajes: string }) => {
            let url = this.USER.logout();
            this.GS.deleteGroups();

            setTimeout(() => {
              window.location.reload();
            }, 10);
          },
          (err) => {
            let url4 = this.USER.logout();
            this.GS.deleteGroups();
            this.RT.navigateByUrl("/login" + url4).then(() => {
              setTimeout(() => {
                window.location.reload();
              }, 10);
            });
          }
        )
        .catch((err) => {
          let url3 = this.USER.logout();
          this.GS.deleteGroups();
          this.RT.navigateByUrl("/login" + url3).then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 10);
          });
        });
    });
  */
  }
}
