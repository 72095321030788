import { Pagination } from '@sha/table/pagination/pagination.interface';
import { Content } from '@dash/teacher/shared/modal/modal.interface';

export enum Status {
  Pendiente = 1,
  Entregado = 2,
  SinEntrega = 3,
}

export class Data {
  data?: Array<Evaluations>;
  pagination?: Pagination;
}

export class Units {
  id: number;
  unit: string;
  activities: Array<{ id: number, activity: string }>;
}

export class Evaluations {
  user?: { name: string; img: string; };
  team?: any;
  status: Status;
  resolution?: number;
  evaluated: boolean;
  qualification: { current: number, total: number };
  highlights?: boolean;
  pdf: string;
}

export class Actividad {
  id?: number;
  user?: { name: String, img: string };
  files?: Array<{ url: string }>;
  answers: Array<Content>;
}

export class Resolution {
  id: number;
  is_confirm: boolean;
  answers: Array<Content>;
  files?: any;
  content?:any
}
