<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group form-animate-text" [ngClass]="{ 'form-animate-error': submitted && f.username.errors }">
    <input type="text" class="form-control" formControlName="username" name="username" autocomplete="off" placeholder="Correo electrónico o username" required>
    <div *ngIf="submitted && f.username.errors" class="invalid-feedback d-block">
        <div *ngIf="f.username.errors.required">El correo electrónico o username es requerido</div>
    </div>
  </div>
  <div class="form-group form-animate-text" [ngClass]="{ 'form-animate-error': submitted && f.password.errors }">
    <input type="password" class="form-control" formControlName="password" name="password" autocomplete="off" placeholder="Contraseña" required>
    <div *ngIf="submitted && f.password.errors" class="invalid-feedback d-block">
        <div *ngIf="f.password.errors.required">Contraseña es requerido</div>
    </div>
  </div>
  <button type="submit" name="button" class="btn btn-primary btn-block btn-lg" [disabled]="btnloader" [ngClass]="{ 'no-drop': btnloader }">
      <i class="fas fa-spinner fa-spin mr-3" *ngIf="btnloader"></i> Inicia Sesión
  </button>
</form>
