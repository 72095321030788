import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ERole } from '@dash/dashboard.config';

@Component({
  selector: 'badge-rol',
  templateUrl: './badge-rol.component.html',
  styles: [`
    .bg-orange {
      background-color: #FF6D00;
    }
    .bg-red {
      background-color: red;
    }
    .bg-cyan {
      background-color: cyan;
    }
    .bg-purpul {
      background-color: #6f42c1;
    }
    .bg-green {
      background-color: green;
    }
    .bg-yellow {
      background-color: #FFBB00;
    }

  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeRolComponent {

  @Input() rol: number;

  constructor() { }

  getColor(rol: number): string {
    switch (rol) {
      case ERole.Admin: return 'bg-orange';
      case ERole.Direction: return 'bg-red';
      case ERole.Moderator: return 'bg-purpul';
      case ERole.Teacher: return 'bg-yellow';
      case ERole.Student: return 'bg-cyan';
      case ERole.Parent: return 'bg-green';
      case ERole.Editor: return 'bg-orange';
    }
  }

  getType(rol: number): string {
    switch (rol) {
      case ERole.Admin: return 'Admin';
      case ERole.Direction: return 'Dirección';
      case ERole.Moderator: return 'Moderator'
      case ERole.Teacher: return 'Maestro';
      case ERole.Student: return 'Alumno';
      case ERole.Parent: return 'Tutores';
      case ERole.Editor: return 'Editor';
    }
  }
}
