import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { DashboardComponent } from "@dash/dashboard.component";
import { AvatarModule } from "@sha/avatar/avatar.module";
import { BadgeRolModule } from "@sha/badge-rol/badge-rol.module";
import { NotificationItemModule } from "@sha/notification/notification.module";
import { OpemNotificationModule } from "@sha/notification/opem-notification/opem-notification.module";
import { LoaderModule } from "@sha/loader/loader.module";
import { SvgModule } from "@sha/svg/svg.module";
import { BreadcrumbModule } from "@sha/breadcrumb/breadcrumb.module";
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";

import { environment } from "@env/environment";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


var str = localStorage.getItem("campuus")?localStorage.getItem("campuus"):'';



function token() {
  return localStorage.getItem("campuus")?localStorage.getItem("campuus"):'';
}


const config: SocketIoConfig = {
  url: environment.wsUrl,
  options: {
    extraHeaders: {
      authorization: decodeURIComponent(escape(window.atob(token())))
    },
  },
};




@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    RouterModule,
    AvatarModule,
    BadgeRolModule,
    LoaderModule,
    BreadcrumbModule,
    ScrollingModule,
    NotificationItemModule,
    OpemNotificationModule,
    ReactiveFormsModule,
    FormsModule,
    SvgModule,
    SocketIoModule.forRoot(config),
  ],
})
export class DashboardModule {}
