<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group form-text">
    <label><i class="fas fa-key"></i> Ingresar contraseña</label>
    <i class="ml-2 fas" [ngClass]="{'fa-eye': !show, 'fa-eye-slash': show}" (click)="show = !show"></i>
    <input type="{{ show ? 'text' : 'password' }}" class="form-control" formControlName="pass" name="pass" autocomplete="off" placeholder="Contraseña" required>
    <div *ngIf="submitted && f.pass.errors" class="invalid-feedback d-block">
         <div *ngIf="f.pass.errors.required">La contraseña es requerido</div>
    </div>
  </div>
  <div class="form-group form-text">
    <input type="{{ show ? 'text' : 'password' }}" class="form-control" formControlName="confirm_password" name="confirm_password" autocomplete="off" placeholder="Confirmar contraseña" required>
    <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback d-block">
         <div *ngIf="f.confirm_password.errors.required">La contraseña es requerido</div>
         <div *ngIf="f.confirm_password.errors.NoPassswordMatch">Las contraseña no son iguales</div>
    </div>
  </div>
  <button type="submit" name="button" class="btn btn-primary btn-block btn-lg" [disabled]="btnloader" [ngClass]="{ 'no-drop': btnloader }">
      <i class="fas fa-spinner fa-spin mr-3" *ngIf="btnloader"></i> Enviar
  </button>
</form>
