import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpemNotificationComponent } from './opem-notification.component';
import { RouterModule } from '@angular/router';
import { TaskStatusModule } from '../../task-status/task-status.module';
import { SvgModule } from '../../svg/svg.module';


@NgModule({
  declarations: [OpemNotificationComponent],
  imports: [
    CommonModule,
    RouterModule,
    TaskStatusModule,
    SvgModule
  ],
  exports: [OpemNotificationComponent]
})
export class OpemNotificationModule { }
