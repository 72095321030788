<nav
  class="navbar navbar-light navbar-expand-lg sticky-top header-top clearfix"
  id="navbar"
>
  <div class="container-fluid">
    <img src="assets/svg/campuus-navbar.svg" alt="escoolar" /><button
      data-toggle="collapse"
      class="navbar-toggler"
      data-target="#navcol-1"
      style="show"
    >
      <span class="sr-only">Toggle navigation</span
      ><span class="navbar-toggler-icon"></span>
    </button>
  </div>
</nav>
<section
  class="d-flex flex-column justify-content-center"
  [style.background-image]="'url(' + images.background + ')'"
>
  <div class="container">
    <div class="row justify-content-center mt-0">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xl-4 my-5">
        <div class="card bg-light shadow" style="border: 0px">
          <div class="card-header text-center p-5" style="background: #001e3c">
            <img [src]="images.logo" alt="" />
          </div>
          <div class="card-footer text-center">
            <h6 class="text-center text-dark mb-3">
              Ingresa tus datos para iniciar sesión
            </h6>
            <router-outlet></router-outlet>
            <h6
              class="text-center text-primary my-3 clickeable"
              data-toggle="modal"
              data-target="#modalauth"
            >
              ¿Olvidaste tu contraseña?
            </h6>
            <!-- <a class="text-primary my-3" data-toggle="modal" data-target="#modalauth">¿Olvidaste tu contraseña?</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<footer class="footer footer-dark">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6 item">
        <img src="assets/svg/campuus-footer.svg" width="200px" />
      </div>
      <div
        class="
          col-sm-6 col-md-6 col-lg-6
          offset-sm-6 offset-md-6 offset-lg-6 offset-xl-0
          text-center
        "
      >
        <img src="assets/svg/banderas.svg" />
      </div>
    </div>
    <p class="copyright">escoolar.com © 2020</p>
  </div>
</footer>

<modal-auth [img]="'assets/images/password.gif'">
  <request-access></request-access>
</modal-auth>
