import { Component, OnInit, Input } from '@angular/core';
import { Status } from '@dash/subjects/activities/evaluations/evaluations.interface';


@Component({
  selector: 'app-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.css']
})
export class TaskStatusComponent implements OnInit {

  @Input() status: number;

  constructor() { }

  ngOnInit(): void {
  }

  getStatus(status: number, color?: boolean): string {
    switch (status) {
      case Status.SinEntrega:
        return color ? '#FF4136' : 'Sin entrega';
      case Status.Pendiente:
        return color ? '#ffbb00' : 'Pendiente';
      case Status.Entregado:
        return color ? '#2962ff' : 'Entregado';
    }
  }
}
