import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '@ser/api.service';
import { ErrorService } from '@ser/error.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CustomPasswordValidators } from '@help/custom-password-validators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../auth.component.css']
})
export class ResetPasswordComponent implements OnInit {

  show: boolean;
  form: FormGroup;
  submitted: boolean = false;
  btnloader: boolean = false;

  constructor(
    private fB: FormBuilder,
    private api: ApiService,
    private err: ErrorService,
    private aR: ActivatedRoute,
    private r: Router
  ) {
    this.form = this.fB.group({
      token: [, [Validators.required]],
      pass: [, [Validators.required]],
      confirm_password: [, [Validators.required]]
    }, {
      validator: CustomPasswordValidators.passwordMatchValidator
    });

    this.aR.queryParams.subscribe(
      (prms: Params) => {
        this.form.patchValue({ token: prms.token });
      }
    );
  }

  ngOnInit(): void {
  }

  get f() { return this.form.controls; }
  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) { return; }
    this.btnloader = true;

    this.api.put('login/reset/', this.form.value).subscribe(() => {
      Swal.fire({
        position: 'center',
        type: 'success',
        title: 'La solicitud de acceso fue enviada correctamente, se notificara al correo registrado cuando la cuenta sea activada.',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        this.btnloader = false;
      }, 3000);
      this.r.navigate([''])
    }, err => {
      this.err.checkError(err);
      setTimeout(() => {
        this.btnloader = false;
      }, 1500);
    });
  }
}
