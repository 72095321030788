import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { UserService } from '@ser/user.service';
import { ERole } from './dashboard.config';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
  constructor(
    public r: Router,
    private u: UserService
  ) { }
  canActivate() {
    if (this.u.get()) {
      return true;
    } else {
      this.r.navigate(['login', this.u.getLogin()])
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanLoad {
  constructor(
    public r: Router,
    private u: UserService
  ) { }
  canLoad() {
    if (this.u.getUser().role == ERole.Admin) {
      return true;
    } else {
      this.r.navigate(['']);
      return false;
    }
  }
}
