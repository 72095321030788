// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// test
// https://projects.propublica.org/nonprofits/api/v2/search.json?page=2&order=revenue&sort_order=desc

export const environment = {
  production: false,
  url:'https://devbackend.campuus.us/v1/',
  cryto:'s5u8x/A?D(G+KbPe',
  schoolIDFlag: 51,
  //url: 'http://8c9597a1f0ee.ngrok.io/campus-backend/',
  //url: 'https://api.escoolar.campuus.us/',
  // url: 'https://campus.dacodes.com/',  url: 'https://api.campuus.us/v1/',---->- este es prod
  //url: 'https://api.campuus.us/v1/',
  /*
  https://dev.api.campuus.us/v1/assets/js/escoolarSSO.js (desarrollo)
https://api.campuus.us/v1/assets/js/escoolarSSO.js (produccion)

  */
  apikeyFroala: "jUA1yB1B1D4D1G3F1pH-7oauC-13B1puxfbmjF3noaG3C2A5D5C4D3D2D4D=2I2=3D=3D",
  wsUrl: 'https://socket.campuus.us:3000/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
